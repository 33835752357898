<template>
  <div class="player-list-container">
    <template v-if="playersGone.length > 0">
      <div style="margin-bottom: 8px;" class="has-text-centered">
        <div
          class="notification notification-disconnect"
          :class="{
            'frosted-background-static': $isSafari,
            'frosted-background': !$isSafari,
          }"
        >
          <span class="ligne1">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_1_joueur_x_gone', [playersGone[0].nom]) }}</span><br/>
          <span class="ligne2">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_2_renvoi_lien') }}</span><br/>
          <span class="lien-partie">{{ windowLocationOrigin() + '/#/salon/' + id_salon }}</span>
          <span class="ligne3">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_3_meme_pseudo') }}</span>

          <div class="separator"></div>

          <span class="ligne1">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_4_ou_sinon') }}</span><br/>
          <span class="ligne2">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_5_option_kick') }}</span><br/>
          <span class="ligne2">{{ $t('sketchy.page_jeu.notif_player_gone.ligne_6_kick_no_rejoin') }}</span>
        </div>
      </div>
    </template>

    <div
      class="player-list"
      :class="{
        'frosted-background-static': $isSafari,
        'frosted-background': !$isSafari,
      }"
    >

      <div v-if="salon_etat === 'e0' && joueurs.length > 0" class="player-host first-separated">
        <TacheRoiVert v-if="joueurs[0].pret"/>
        <TacheRoi v-else/>

        <span
          class="player-host-name"
          :class="{'is-underlined': isHost}"
        >{{ joueurs[0].nom }}</span>
      </div>
      <div v-if="salon_etat === 'e0' ">
        <div v-if="joueurs.length < 2" style="font-family: cabin_sketch_regular;color: red;text-decoration: underline;">
          <i>{{ $t('sketchy.page_jeu.player_list.minimum_player') }}</i>
        </div>

        <div v-else-if="joueurs.length < 4"
             style="font-family: cabin_sketch_regular;color: #b77800;text-decoration: underline;">
          <i>{{ $t('sketchy.page_jeu.player_list.minimum_conseil_$d', [4]) }}</i>
        </div>
      </div>
      <div
        class="player-regular"
        v-for="(joueur, i) in joueurs"
        v-if="salon_etat !== 'e0' || i > 0"
      >

        <BoutonKick
          class="kick-btn"
          v-if="(isHost && salon_etat === 'e0') || !joueur.alive"
          @click="kickJoueurNom(joueur.nom)"
        />

        <template v-if="salon_etat === 'e0'">
          <IconeReseauHs v-if="!joueur.alive" class="icone-etat"/>
          <IconeCheck v-else-if="joueur.pret" class="icone-etat"/>
          <IconeSablier v-else class="icone-etat"/>
        </template>

        <template v-else-if="salon_etat === 'e2'">
          <IconeReseauHs v-if="!joueur.alive" class="icone-etat"/>
          <IconeCheck v-else-if="joueur.pret" class="icone-etat"/>
          <IconePinceauPetit v-else class="icone-etat"/>
        </template>

        <template v-else-if="salon_etat === 'e1' || salon_etat === 'e3'">
          <IconeReseauHs v-if="!joueur.alive" class="icone-etat"/>
          <IconeCheck v-else-if="joueur.mot_choisi" class="icone-etat"/>
          <IconeSablier v-else class="icone-etat"/>
        </template>

        <template v-else-if="salon_etat === 'e4'">
          <span
            class="cursor-color-dot"
            :style="{
              filter: 'grayscale(40%) brightness(600%) hue-rotate(' + i * 60 + 'deg)',
            }"
          ></span>
        </template>

        <span
          class="player-name"
          :class="{'is-underlined': index_joueur === i}"
        >{{ joueur.nom }}</span>

        <span class="player-score" v-if="salon_etat === 'e2' && joueur.alive && joueur.e2_player_countdown !== null">
          {{ joueur.e2_player_countdown }}s
        </span>
        <span class="player-score" v-else-if="joueur.score!== null && salon_etat !== 'e0' && salon_etat !== 'e1'">
          Score{{ $t(':') }} {{ joueur.score }}
        </span>
      </div>


      <div style="margin-top: 14px;" v-if="salon_etat === 'e4'">
        <button
          class="button is-small is-outlined is-danger"
          @click="quitterPartie"
        >
          {{ $t('sketchy.page_jeu.etat_4.retour_menu') }}
        </button>

        <button
          class="button is-small is-outlined is-success"
          @click="relancerPartie"
        >
          {{ $t('sketchy.page_jeu.etat_4.relancer') }}
        </button>
      </div>

      <div style="margin-top: 14px;" v-if="salon_etat === 'e0'">
        <E0BoutonPret
          :style="{'min-width': $locale === 'fr' ? '85px' : '94px'}"
          @click="btnPret"
          :pret="pret"
        />

        <button
          v-if="isHost"
          @click="lancerPartie"
          :disabled="!everyoneReady"
          class="button is-small"
          style="margin-left: 2px;"
        >{{ $t('sketchy.page_jeu.player_list.lancer_partie') }}
        </button>
      </div>

      <div v-if="salon_etat === 'e0' && isHost &&joueurs.length > 1">
        <div v-if="!everyoneReady" style="font-family: cabin_sketch_regular;color: #b77800;margin-top: 5px">
          <i>{{ $tc('sketchy.page_jeu.player_list.joueurs_pas_prets', nbReady) }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~@/resources/libraries/bulma-0.9.0/sass/utilities/_all.sass';

$border-color: rgba(0, 0, 0, 0.2);

.is-underlined {
  //text-decoration: underline;
  color: #136a9e !important;
}

.player-list-container {
  text-align: center;

  .notification-disconnect {
    display: inline-block;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 9px;
    padding-right: 9px;
    max-width: 325px;

    .separator {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.3);

      margin-top: 10px;
      margin-bottom: 6px;
    }

    .ligne1 {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }

    .ligne2 {
      font-size: 12px;
      color: #606060;
    }

    .ligne3 {
      font-size: 13px;
      font-weight: bold;
      text-decoration: underline;
      color: #d01313;
    }

    .lien-partie {
      display: block;
      font-size: 12px;
      color: black;
      background-color: white;
      border-radius: 4px;
      padding-left: 1px;
      padding-right: 1px;

      -webkit-user-select: all;  /* Chrome 49+ */
      -moz-user-select: all;     /* Firefox 43+ */
      -ms-user-select: all;      /* No support yet */
      user-select: all;
    }

    border: 1px solid $border-color;
    border-radius: 12px;
  }

  .first-separated:not(:last-of-type):first-of-type {
    border-bottom: 1px solid $border-color;
  }

  .player-list {
    display: inline-block;
    min-width: 63%;
    max-width: 350px;

    border-radius: 12px;

    border: 1px solid $border-color;

    padding-top: 4px;
    padding-bottom: 8px;

    padding-left: 13px;
    padding-right: 13px;

    @include mobile {
      padding-left: 3px;
      padding-right: 3px;
      width: 100%;
    }

    .player-host {
      overflow: hidden;
      white-space: nowrap;

      text-align: center;
      height: 50px;
      line-height: 50px;

      .player-host-name {
        vertical-align: top;
        font-family: cabin_sketch_regular;
        font-size: 26px;
        width: 100%;
        margin-left: 9px;
        margin-right: 9px;
        color: black;
      }
    }

    .player-regular {
      position: relative;
      //overflow: hidden;
      white-space: nowrap;
      height: 45px;
      text-align: left;
      margin-left: 20px;
      font-size: 22px;
      line-height: 45px;
      margin-top: 6px;

      .cursor-color-dot {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 13px;
        background-color: red;
      }

      .player-name {
        display: inline-block;
        vertical-align: top;
        font-family: cabin_sketch_regular;
        margin-left: 9px;
        color: black;
        margin-right: 22px;
        width: calc(100% - 55px);
        overflow: hidden;
      }

      .player-score {
        position: absolute;
        font-family: cabin_sketch_regular;
        display: block;
        $margin-left: 80px;
        $margin-right: 15px;
        width: calc(100% - #{$margin-left + $margin-right});
        height: 15px;
        bottom: 0;
        left: $margin-left;
        line-height: 18px;
        font-size: 15px;
      }
    }

    .icone-etat {
      height: 45px;
    }

    .kick-btn {
      position: absolute;
      //bottom: -20px;
      bottom: 8px;
      //left: 64px;
      left: -27px;
      height: 28px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 3px;
      padding-right: 3px;
      opacity: 0.6;

      &:hover{
        opacity: 1;
      }
      @include mobile{
        height: 25px;
        left: -20px;
        bottom: 10px;


      }
    }
  }
}
</style>

<script>
export default {
  name: 'PlayerList',

  data() {
    return {
      joueurs: [
        // {nom: 'Jean', pret: false, mot_choisi: false},
        // {nom: 'Jeanne', pret: true, mot_choisi: true},
        // {nom: 'Jeannette', pret: false, mot_choisi: false},
        // {nom: 'Jeannettoire', pret: true, mot_choisi: true},
      ],
    };
  },

  computed: {
    isHost() {
      return this.index_joueur === 0;
    },

    everyoneReady() {
      if (this.joueurs.length < 2) {
        if (!this.$dev)
          return false;
      }
      for (let i in this.joueurs) {
        if (!this.joueurs[i].pret) {
          return false;
        }
      }
      return true;
    },

    nbReady() {
      let nb = 0;
      for (let i in this.joueurs) {
        if (!this.joueurs[i].pret) {
          nb++;
        }
      }
      return nb;
    },

    pret() {
      if (this.index_joueur !== undefined && this.index_joueur !== null) {
        return this.joueurs[this.index_joueur] !== null &&
          this.joueurs[this.index_joueur] !== undefined &&
          'pret' in this.joueurs[this.index_joueur] &&
          this.joueurs[this.index_joueur].pret;
      }
      return false;
    },

    playersGone() {
      if (this.salon_etat === 'e1' || this.salon_etat === 'e2' || this.salon_etat === 'e3') {
        let p = [];

        for (let i in this.joueurs)
          if (this.joueurs[i].alive === false)
            p.push(this.joueurs[i]);

        return p;
      } else {
        return [];
      }
    },
  },

  watch: {
    'salon_etat': {
      immediate: true,
      handler: function (salon_etat) {
        if (salon_etat === 'e2') {
          for (let i in this.joueurs)
            this.joueurs[i].pret = false;
        } else if (salon_etat === 'e3') {
          for (let i in this.joueurs)
            this.joueurs[i].mot_choisi = false;
        }
      },
    },
  },

  methods: {
    kickJoueurNom(nomJoueur) {
      this.$emit('sendMsg', 'pl_kick', nomJoueur);

      if (this.salon_etat === 'e0') {
        this._matomo_trackGoal(this.MTM_GOAL_KICK_E0);
      } else if (this.salon_etat === 'e1' || this.salon_etat === 'e2' || this.salon_etat === 'e3') {
        this._matomo_trackGoal(this.MTM_GOAL_KICK_IN_GAME);
      }
    },

    windowLocationOrigin() {
      return window.location.origin;
    },

    btnPret() {
      this.$emit('interact');
      this.$emit('sendMsg', 'pl_pret');
    },

    getJoueurNom(i) {
      if (i >= 0 && i < this.joueurs.length)
        return this.joueurs[i].nom;
      return null;
    },

    reset() {
      this.joueurs = [];
    },

    lancerPartie() {
      this.$emit('interact');

      this.$emit('sendMsg', 'g_start');
    },

    quitterPartie() {
      this.$emit('interact');

      this.$session._last_salon = undefined;
      this.$session._last_pseudo = undefined;
      this.$session._last_password = undefined;

      this.$router.push({name: 'Accueil'});
    },

    relancerPartie() {
      this.$emit('interact');

      this.$session._last_salon = undefined;
      this.$session._last_pseudo = undefined;
      this.$session._last_password = undefined;

      this.$emit('sendMsg', 'e4_restart');

      this._matomo_trackGoal(this.MTM_GOAL_GAME_RESTART);
    },

    onMessage(msg_type, msg_data) {
      console.log('msg_data =', msg_data);

      switch (msg_type) {
        // msg_data = [{nom: 'Jean', pret: false}, {nom: 'Pierre', pret: true}, ...]
        case 'pl':
          for (let i in msg_data) {
            this.joueurs.push({
              nom: msg_data[i].nom,
              pret: msg_data[i].pret,
              mot_choisi: false,
              alive: true,
              has_restarted: false,
              score: null,
              e2_player_countdown: null,
            });
          }

          break;

        // msg_data = 'Jean'
        case 'pl_connect':
          this.joueurs.push({
            nom: msg_data,
            pret: false,
            mot_choisi: false,
            alive: true,
            has_restarted: false,
            score: null,
            e2_player_countdown: null,
          });

          if (this.salon_etat === 'e0')
            this.$emit('soundReady', -2);

          if (this.salon_etat === 'e4')
            this.$emit('soundReady', this.joueurs.length);

          break;

        // msg_data = 'Jean'
        case 'pl_disconnect':
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data) {
              if (!this.joueurs[i].has_restarted) {
                this.$emit('chatboxPush', {
                  '$t': 'sketchy.page_jeu.chatbox.deconnexion',
                  '$t_params': [this.joueurs[i].nom],
                  'color': '#970700'
                });
              }

              this.joueurs.splice(i, 1);

              if (this.salon_etat === 'e0')
                this.$emit('soundReady', -5);

              if (this.salon_etat === 'e4')
                this.$emit('soundReady', this.joueurs.length - 3);

              break;
            }
          }
          break;

        case 'pl_kicked':
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data) {
              this.$emit('chatboxPush', {
                '$t': 'sketchy.page_jeu.chatbox.kick',
                '$t_params': [msg_data],
                'color': '#970700',
              });

              this.joueurs.splice(i, 1);

              this.$emit('soundReady', -5);
              break;
            }
          }
          break;

        // msg_data = 'Jean'
        case 'pl_gone':
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data) {
              this.joueurs[i].alive = false;
              this.joueurs[i].e2_player_countdown = null;
              break;
            }
          }

          if (this.salon_etat === 'e1' || this.salon_etat === 'e2' || this.salon_etat === 'e3')
            this.$emit('soundReady', -5);
          break;

        // msg_data = 'Jean'
        case 'pl_back':
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data) {
              this.joueurs[i].alive = true;
              this.joueurs[i].e2_player_countdown = null;
              break;
            }
          }

          if (this.salon_etat === 'e1' || this.salon_etat === 'e2' || this.salon_etat === 'e3')
            this.$emit('soundReady', -2);
          break;

        // msg_data = {nom: 'Jean', pret: true}
        case 'pl_state':
          let nbJoueursPrets = 0;
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data.nom)
              this.joueurs[i].pret = msg_data.pret;

            if (this.joueurs[i].pret) {
              this.joueurs[i].e2_player_countdown = null;
              nbJoueursPrets++;
            }
          }

          if (this.salon_etat === 'e0')
            this.$emit('soundReady', nbJoueursPrets);
          break;

        // msg_data = "Jean""
        case 'e1_mot_pret':
        case 'e3_mot_pret':
          let nbJoueursChoisis = 0;
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data)
              this.joueurs[i].mot_choisi = true;

            if (this.joueurs[i].mot_choisi)
              nbJoueursChoisis += 1;
          }
          this.$emit('soundReady', nbJoueursChoisis);
          break;

        // msg_data = 'Jean'
        case 'pl_restarted':
          for (let i in this.joueurs) {
            if (this.joueurs[i].nom === msg_data) {
              this.joueurs[i].has_restarted = true;
              break;
            }
          }
          break;

        case 'pl_scores' :
          console.log("SCORES : ");
          console.log(msg_data);
          for (let i in this.joueurs) {
            if(this.joueurs[i].nom in msg_data){
              this.joueurs[i].score = msg_data[this.joueurs[i].nom];
            }
          }
          break;

        case 'e2_player_countdown':
          for (let i in this.joueurs) {
            if(this.joueurs[i].nom === msg_data.j) {
              this.joueurs[i].e2_player_countdown = msg_data.c;
              break;
            }
          }
          break;
      }
    }
  },

  components: {
    IconeCheck: () => import('@/resources/icones_maison/IconeCheck'),
    IconePinceauGros: () => import('@/resources/icones_maison/IconePinceauGros'),
    IconePinceauPetit: () => import('@/resources/icones_maison/IconePinceauPetit'),
    IconeReseauHs: () => import('@/resources/icones_maison/IconeReseauHs'),
    IconeSablier: () => import('@/resources/icones_maison/IconeSablier'),

    TacheRoi: () => import('@/resources/images/TacheRoi'),
    TacheRoiVert: () => import('@/resources/images/TacheRoiVert'),

    E0BoutonPret: () => import('./E0BoutonPret'),
    BoutonKick: () => import('../global/boutons/BoutonKick'),
  },

  props: ['nom_joueur', 'index_joueur', 'salon_etat', 'id_salon'],
}
</script>
