var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player-list-container"},[(_vm.playersGone.length > 0)?[_c('div',{staticClass:"has-text-centered",staticStyle:{"margin-bottom":"8px"}},[_c('div',{staticClass:"notification notification-disconnect",class:{
          'frosted-background-static': _vm.$isSafari,
          'frosted-background': !_vm.$isSafari,
        }},[_c('span',{staticClass:"ligne1"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_1_joueur_x_gone', [_vm.playersGone[0].nom])))]),_c('br'),_c('span',{staticClass:"ligne2"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_2_renvoi_lien')))]),_c('br'),_c('span',{staticClass:"lien-partie"},[_vm._v(_vm._s(_vm.windowLocationOrigin() + '/#/salon/' + _vm.id_salon))]),_c('span',{staticClass:"ligne3"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_3_meme_pseudo')))]),_c('div',{staticClass:"separator"}),_c('span',{staticClass:"ligne1"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_4_ou_sinon')))]),_c('br'),_c('span',{staticClass:"ligne2"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_5_option_kick')))]),_c('br'),_c('span',{staticClass:"ligne2"},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.notif_player_gone.ligne_6_kick_no_rejoin')))])])])]:_vm._e(),_c('div',{staticClass:"player-list",class:{
      'frosted-background-static': _vm.$isSafari,
      'frosted-background': !_vm.$isSafari,
    }},[(_vm.salon_etat === 'e0' && _vm.joueurs.length > 0)?_c('div',{staticClass:"player-host first-separated"},[(_vm.joueurs[0].pret)?_c('TacheRoiVert'):_c('TacheRoi'),_c('span',{staticClass:"player-host-name",class:{'is-underlined': _vm.isHost}},[_vm._v(_vm._s(_vm.joueurs[0].nom))])],1):_vm._e(),(_vm.salon_etat === 'e0' )?_c('div',[(_vm.joueurs.length < 2)?_c('div',{staticStyle:{"font-family":"cabin_sketch_regular","color":"red","text-decoration":"underline"}},[_c('i',[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.player_list.minimum_player')))])]):(_vm.joueurs.length < 4)?_c('div',{staticStyle:{"font-family":"cabin_sketch_regular","color":"#b77800","text-decoration":"underline"}},[_c('i',[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.player_list.minimum_conseil_$d', [4])))])]):_vm._e()]):_vm._e(),_vm._l((_vm.joueurs),function(joueur,i){return (_vm.salon_etat !== 'e0' || i > 0)?_c('div',{staticClass:"player-regular"},[((_vm.isHost && _vm.salon_etat === 'e0') || !joueur.alive)?_c('BoutonKick',{staticClass:"kick-btn",on:{"click":function($event){return _vm.kickJoueurNom(joueur.nom)}}}):_vm._e(),(_vm.salon_etat === 'e0')?[(!joueur.alive)?_c('IconeReseauHs',{staticClass:"icone-etat"}):(joueur.pret)?_c('IconeCheck',{staticClass:"icone-etat"}):_c('IconeSablier',{staticClass:"icone-etat"})]:(_vm.salon_etat === 'e2')?[(!joueur.alive)?_c('IconeReseauHs',{staticClass:"icone-etat"}):(joueur.pret)?_c('IconeCheck',{staticClass:"icone-etat"}):_c('IconePinceauPetit',{staticClass:"icone-etat"})]:(_vm.salon_etat === 'e1' || _vm.salon_etat === 'e3')?[(!joueur.alive)?_c('IconeReseauHs',{staticClass:"icone-etat"}):(joueur.mot_choisi)?_c('IconeCheck',{staticClass:"icone-etat"}):_c('IconeSablier',{staticClass:"icone-etat"})]:(_vm.salon_etat === 'e4')?[_c('span',{staticClass:"cursor-color-dot",style:({
            filter: 'grayscale(40%) brightness(600%) hue-rotate(' + i * 60 + 'deg)',
          })})]:_vm._e(),_c('span',{staticClass:"player-name",class:{'is-underlined': _vm.index_joueur === i}},[_vm._v(_vm._s(joueur.nom))]),(_vm.salon_etat === 'e2' && joueur.alive && joueur.e2_player_countdown !== null)?_c('span',{staticClass:"player-score"},[_vm._v(" "+_vm._s(joueur.e2_player_countdown)+"s ")]):(joueur.score!== null && _vm.salon_etat !== 'e0' && _vm.salon_etat !== 'e1')?_c('span',{staticClass:"player-score"},[_vm._v(" Score"+_vm._s(_vm.$t(':'))+" "+_vm._s(joueur.score)+" ")]):_vm._e()],2):_vm._e()}),(_vm.salon_etat === 'e4')?_c('div',{staticStyle:{"margin-top":"14px"}},[_c('button',{staticClass:"button is-small is-outlined is-danger",on:{"click":_vm.quitterPartie}},[_vm._v(" "+_vm._s(_vm.$t('sketchy.page_jeu.etat_4.retour_menu'))+" ")]),_c('button',{staticClass:"button is-small is-outlined is-success",on:{"click":_vm.relancerPartie}},[_vm._v(" "+_vm._s(_vm.$t('sketchy.page_jeu.etat_4.relancer'))+" ")])]):_vm._e(),(_vm.salon_etat === 'e0')?_c('div',{staticStyle:{"margin-top":"14px"}},[_c('E0BoutonPret',{style:({'min-width': _vm.$locale === 'fr' ? '85px' : '94px'}),attrs:{"pret":_vm.pret},on:{"click":_vm.btnPret}}),(_vm.isHost)?_c('button',{staticClass:"button is-small",staticStyle:{"margin-left":"2px"},attrs:{"disabled":!_vm.everyoneReady},on:{"click":_vm.lancerPartie}},[_vm._v(_vm._s(_vm.$t('sketchy.page_jeu.player_list.lancer_partie'))+" ")]):_vm._e()],1):_vm._e(),(_vm.salon_etat === 'e0' && _vm.isHost &&_vm.joueurs.length > 1)?_c('div',[(!_vm.everyoneReady)?_c('div',{staticStyle:{"font-family":"cabin_sketch_regular","color":"#b77800","margin-top":"5px"}},[_c('i',[_vm._v(_vm._s(_vm.$tc('sketchy.page_jeu.player_list.joueurs_pas_prets', _vm.nbReady)))])]):_vm._e()]):_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }